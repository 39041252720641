
// Email Offers Form.
$('#email-offers-form').on('submit', function (e) {
    $('#emailOffersError').addClass('d-none');
    $('#email-offers-submit-btn').prop('disabled', true);

    const homedir = $('#email-offers-form').data('homedir');
    const clientcode = $('#email-offers-form').data('clientcode');

    $.ajax({
        type: 'POST',
        url: `./${homedir}/email-offers`,
        data: {
            email: $('#offers_email').val(),
            clientcode,
        },
        success: function (response) {
            if (!response.success) {
                $('#email-offers-submit-btn').prop('disabled', false);
                $('#emailOffersError').removeClass('d-none');
                return false;
            }
            console.log('response: ', response);
            $('#emailOffersThanks').removeClass('d-none');
            $('#email-offers-form').addClass('d-none');
            return false;
        },
        error: function (err) {
            console.log(err.responseText);
            $('#email-offers-submit-btn').prop('disabled', false);
            $('#emailOffersError').removeClass('d-none');
            return false;
        },
    });

});
